import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatCurrency(amount: number) {
  return amount.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
  });
}

// export function currencyToNumber(entry: string) {
//   const numberString = entry.replace(/[^\d.,]/g, '').replace(',', '.');
//   return +numberString;
// }

/**
 * Refine a ZodTypeAny to always run the validation of the given ZodTypeAny
 * @param zodType
 * See: https://github.com/colinhacks/zod/issues/2524#issuecomment-2293141825
 */
export function zodAlwaysRefine<T extends z.ZodTypeAny>(zodType: T) {
  return z.any().superRefine(async (value, ctx) => {
    const res = await zodType.safeParseAsync(value);

    if (res.success === false)
      for (const issue of res.error.issues) {
        ctx.addIssue(issue);
      }
  }) as unknown as T;
}
